<template>
  <img
    src="~assets/image/tab_banner/thumbnail/news_thumbnail.jpg"
    alt="新闻中心"
    class="page-banner"
    style="width: 100%"
    v-lazyLoadImage="require('assets/image/tab_banner/news.png')"
  />
  <div class="content">
    <div class="left-nav"><tab-menu :menu-list="menuList" /></div>
    <div class="right-contact">
      <div class="title-box">
        <point-title :title="title_name" />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import TabMenu from "components/TabMenu.vue";
import { useRoute } from "vue-router";
import { computed, onMounted, ref } from "vue";
import PointTitle from "@/components/PointTitle.vue";
const menuList = [
  {
    name: "学术前沿",
    uri: "/Academic",
  },
  {
    name: "新闻政策",
    uri: "/NewsPolicy",
  },
  {
    name: "研究院动态",
    uri: "/ComCondition",
  },
];
export default {
  components: { TabMenu, PointTitle },
  name: "NewsCenter",
  setup() {
    const route = useRoute();
    let name = "";
    const title_name = computed(() => {
      if (route.name == "NewsContact") {
        return name;
      } else {
        return (name = route.meta.name);
      }
    });

    return { title_name, menuList };
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 30px 0 80px 0;
  box-sizing: border-box;
  .left-nav {
    width: 230px;
    background-color: #fff;
  }
  .right-contact {
    width: 930px;
    margin-left: 40px;
    background-color: #fff;
    .title-box {
      padding: 15px 0 15px 20px;
      border-bottom: 1px solid #f4f4f4;
    }
  }
}
::v-deep(.el-menu-vertical-demo > div > .el-menu-item) {
  text-align: center;
}
</style>
>
